/**
 * General Javascript for both frontend and backend.
 */
import './bootstrap';

import.meta.glob([
    '../images/**',
    '../fonts/**',
]);

document.querySelectorAll('.my-js-back-button').forEach(function(el) {
    el.addEventListener('click', function(e) {
        e.preventDefault();
        window.history.back();
    })
});